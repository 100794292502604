<template>
  <v-card flat class="mb-2 pa-2  lighten-5">
    <v-card-text>
      <v-row>
        <v-col xs="12">
          <v-progress-linear
            indeterminate
            color="primary"
            v-if="processing"
          ></v-progress-linear>
          <v-subheader style="font-size: 16px !important"
            >Detalle Comisiones por Componentes</v-subheader
          >
          <div ref="amchart" style="height: 45vh"></div>
          <br />
          <v-alert
            dense
            type="warning"
            text
            v-show="!processing && isChartEmpty"
            >No existe información para esta gráfica</v-alert
          >
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import apiDashboard from "@/api/dashboards";
import { mapActions } from "vuex";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themesAnimated from "@amcharts/amcharts4/themes/material";
import { store } from "@/store";
import * as am4pluginsRegression from "@amcharts/amcharts4/plugins/sliceGrouper";

am4core.useTheme(am4themesAnimated);

export default {
  props: ["corteId", "tipoProducto"],
  data() {
    return {
      processing: false,
      chartData: [],
      chart: null,
      headers: [
        { text: "PERIODO", value: "Periodo" },
        { text: "CONCEPTO", value: "Concepto" },
        { text: "VALOR", value: "Monto" },
      ],
    };
  },
  computed: {
    isChartEmpty() {
      return this.chartData.length === 0;
    },
  },
  methods: {
    ...mapActions(["setError", "setErrorMessage", "setMessage"]),
    generateChart(dataParam) {
      if (this.chart) {
        this.chart.dispose();
      }

      try {
        let chart = am4core.create(this.$refs.amchart, am4charts.PieChart3D);
        chart.legend = new am4charts.Legend();
        chart.legend.position = "right";
        chart.exporting.menu = new am4core.ExportMenu();
        chart.numberFormatter.numberFormat = "$#,###.##";

        let data = [];
        dataParam.forEach(function (row) {
          let newRow = {
            Componente: row.Concepto,
            Monto: row.Monto,
          };
          data.push(newRow);
        });
        chart.data = data;

        let serie = chart.series.push(new am4charts.PieSeries3D());
        serie.dataFields.value = "Monto";
        serie.dataFields.category = "Componente";
        serie.innerRadius = am4core.percent(10);
        serie.labels.template.disabled = true;
        serie.ticks.template.disabled = true;

        let grouper = serie.plugins.push(
          new am4pluginsRegression.SliceGrouper()
        );
        grouper.threshold = 5;
        grouper.groupName = "Otros";
        grouper.clickBehavior = "zoom";

        serie.slices.template.states.getKey("hover").properties.shiftRadius = 0;
        serie.slices.template.states.getKey("hover").properties.scale = 1.1;

        this.chart = chart;
      } catch (e) {
        // empty
      }
    },
    refresh() {
      if (this.corteId) {
        this.processing = true;
        let param = {
          CorteId: this.corteId,
          TipoProductoList: Array.from(this.tipoProducto),
          Chart: "ConceptosDetalle",
          AsociadoId: store.getters.user.asociado.id,
        };

        apiDashboard
          .getChart(param)
          .then((response) => {
            this.chartData = response.data;
            this.generateChart(this.chartData);
            this.processing = false;
          })
          .catch((error) => {
            this.setError(error);
            this.processing = false;
          });
      }
    },
  },
  watch: {
    corteId: function () {
      this.refresh();
    },
    tipoProducto: function () {
      this.refresh();
    },
  },
  mounted() {
    this.refresh();
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
  },
};
</script>

<style scope>
.v-alert.v-sheet.theme--dark.v-alert--dense.warning {
  margin-bottom: 0;
}
</style>